.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .scroll-to-top-button.visible {
    opacity: 1;
  }
  