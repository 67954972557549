.calendar-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    font-size: 14px;
  }
  
  .react-datepicker__input-container input::placeholder {
    color: #6c757d;
  }
  
  .react-datepicker__input-container input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  