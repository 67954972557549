.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .card-text {
    font-size: 1rem;
  }
  
  .red-button {
    background-color: #ff6b6b;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .red-button:hover {
    background-color: #ff4f4f;
  }
  
  