body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

.circle-img {
  position: relative;
  float: right;
  border-radius: 50%;
  border: 3px solid black;
  width: 120px;
  height: 120px;
  margin-top: -12%;
  margin-right: 11%;
}
.Profile {
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 20px;
}

h2{
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.red-button {
  background-color: red !important;
  color: white !important;
}

.card-img-top.user-image {
  width: 20%; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Add border-radius for rounded corners */
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
  margin-bottom: 10px; /* Add margin as needed */
  margin-right: 20px;
}